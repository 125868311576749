/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-12-09",
    versionChannel: "nightly",
    buildDate: "2024-12-09T00:07:03.048Z",
    commitHash: "51a6254f1dcc64c8913ecfafb31be7c44a7cc95b",
};
